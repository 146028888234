body {
  background-color: #263344;
  margin: 0;
  font-family: Arial, sans-serif;
}

.map-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #263344;
}

.map-container {
  width: 80%;
  height: 500px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.map-logo {
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
  z-index: 10;
}
.card-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.playing-card {
  width: 300px;
  height: 450px;
  position: relative;
  perspective: 1000px;
  cursor: pointer;
}

.card-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.playing-card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden; /* Hides the back while front is showing */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: white;
  border: 15px solid white;
}

.card-front img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-back {
  transform: rotateY(180deg); /* Flip the back so it's hidden initially */
  background-color: #263344;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2em;
  padding: 20px;
}


.card-title {
  font-size: 1.4em;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #1a1a1a;
}

.card-body {
  font-size: 1em;
  color: #333;
  margin-bottom: 15px;
}

.card-cost {
  font-size: 0.9em;
  font-weight: bold;
  color: #000;
}

.card-back img {
  display: none; /* Prevent the back image from showing */
}
/* Add this to your CSS file (jetlag.css) */

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #263344; /* Same background color as the rest of the page */
    z-index: 9999; /* Ensure it's on top of other elements */
  }
  
  .loading-screen img {
    width: 80%; /* You can adjust this to be almost full screen */
    height: auto;
    object-fit: contain;
  }
  